<template>
  <v-container fluid>
    <v-card flat class='wd-100'>
      <v-card-title class='py-4'>
        <v-spacer />
        <v-text-field
          v-model='search'
          append-icon='mdi-magnify'
          :label='$t("SEARCH")'
          single-line
          hide-details
          clearable
        />
        <v-btn
          outlined
          color='warning'
          class='ml-2'
          elevation='0'
          @click='recordsetIntervation'
        >
          {{ $t('CORRECT_REGISTRATIONS') }}
        </v-btn>
      </v-card-title>
      <v-data-table
        v-model='selected'
        item-key='person_id'
        class='wd-100'
        show-select
        multi-sort
        :search='search'
        :headers='header'
        :items='list'
        :footer-props='{
          itemsPerPageOptions: [30, 50, 100, 300, -1]
        }'
      >
        <template #item.created_at='{ item }'>
          <div>
            {{formattedDate(item.created_at)}}
          </div>
        </template>
        <template #item.actions='{ item }'>
          <v-col>
            <v-row>
              <v-tooltip v-if='item.code == "ASSINATURA_INVALIDA" || item.code == "RETRATO_INVALIDO" || item.code == "DIGITAL_INVALIDA"' bottom>
                <template #activator='{ on, attrs }'>
                  <v-btn
                    color='primary'
                    small
                    icon
                    v-bind='attrs'
                    @click='openEditModal(item)'
                    v-on='on'
                  >
                    <v-icon>
                      mdi-image-edit
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('CORRECT_REGISTRATION') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator='{ on, attrs }'>
                  <v-btn
                    color='primary'
                    small
                    icon
                    v-bind='attrs'
                    @click='toggleEnrolled(item)'
                    v-on='on'
                  >
                    <v-icon>
                      mdi-account-check-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('CHANGE_CANDIDATE_STATE') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator='{ on, attrs }'>
                  <v-btn
                    color='error'
                    small
                    icon
                    v-bind='attrs'
                    @click='deletePerson(item)'
                    v-on='on'
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('DELETE_REGISTRATION') }}</span>
              </v-tooltip>
            </v-row>
          </v-col>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model='showEditImageModal' transition='dialog-top-transition' max-width='700'>
      <v-card>
        <v-col>
          <v-row class='py-5 d-flex justify-center align-center'>
            <v-col>
              <v-tooltip bottom>
                <template #activator='{ on, attrs }'>
                  <v-card
                    flat
                    v-bind='attrs'
                    @click='downloadImage'
                    v-on='on'
                  >
                    <v-img
                      :src='`data:image/charset=UTF-8;png;base64,${imageFromUserInEdit}`'
                      class='grey lighten-2 pointer'
                      style='margin-left: 26px; border: 1px solid #eaeaea'
                      max-width='250'
                      max-height='350'
                    />
                  </v-card>
                </template>
                <span>{{ $t('DOWNLOAD_IMAGE') }}</span>
              </v-tooltip>
            </v-col>
            <v-divider
              class='mx-5'
              vertical
              style='height: 300px; align-self:center'
            />
            <v-col>
              <v-tooltip bottom>
                <template #activator='{ on, attrs }'>
                  <v-card
                    v-if='newBlobImageToUserInEdit'
                    flat
                    v-bind='attrs'
                    v-on='on'
                  >
                    <v-img
                      :src='newBlobImageToUserInEdit'
                      class='grey lighten-2 cursor-pointer'
                      style='margin-left: 26px; border: 1px solid #eaeaea'
                      max-width='250'
                      max-height='350'
                      @click='uploadImage()'
                    />
                  </v-card>
                  <v-card
                    v-else
                    class='d-flex justify-center pointer'
                    outlined
                    height='350'
                    v-bind='attrs'
                    @click='uploadImage()'
                    v-on='on'
                  >
                    <v-icon
                      x-large
                      style='font-size: 200px'
                    >
                      mdi-account
                    </v-icon>
                  </v-card>
                </template>
                <span>{{ $t('UPLOAD_IMAGE') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-file-input
            ref='imageInput'
            v-model='newImageToUserInEdit'
            accept='image/jpeg'
            truncate-length='25'
            class='d-none'
            @change='verifyImage'
          />
          <v-row class='ma-3 d-flex justify-end'>
            <v-btn
              outlined
              color='error'
              class='mr-3'
              @click='dismissEditModal'
            >
              {{ $t('CANCEL') }}
            </v-btn>
            <v-btn
              :disabled='!allowSendBtn'
              color='success'
              @click='sendImage'
            >
              {{ sendRetry > 0 ? $t('RETRY') : $t('CONFIRM') }}
            </v-btn>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { format, parseISO } from 'date-fns';
  import { showError, showSuccess, showWarning } from '../../util/notification';

  export default {
    name: 'ManualIntervention',
    data: function () {
      return {
        search: undefined,
        selected: [],
        header: [
          {
            text: '#',
            value: 'person_id',
            width: '30',
            align: 'center',
          },
          { text: this.$t('NAME'), value: 'full_name' },
          { text: this.$t('CPF'), value: 'cpf' },
          { text: this.$t('RENACH'), value: 'renach' },
          { text: this.$t('CREATION_DATE'), value: 'created_at' },
          { text: this.$t('CODE'), value: 'code' },
          { text: this.$t('MESSAGE'), value: 'message', width: 200 },
          {
            text: '',
            sortable: false,
            value: 'actions',
            width: '120',
            align: 'center',
          },
        ],
        list: [],
        showEditImageModal: false,
        userInEdit: null,
        imageFromUserInEdit: null,
        newImageToUserInEdit: null,
        newBlobImageToUserInEdit: null,
        userData: null,
        imageInBase64: null,
        allowSendBtn: false,
        sendRetry: 0,
      };
    },
    mounted: function () {
      this.getItems();
    },
    methods: {
      getItems: async function () {
        try {
          const { data } = await axios({
            url: '/entry-manual-intervention',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.list = (data && data.list) || [];
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      recordsetIntervation: async function () {
        if (this.selected.length > 0) {
          const ids = [];

          this.selected.forEach((record) => {
            ids.push(record.person_id);
          });

          try {
            const data = await axios({
              url: '/entry-manual-intervention-retry',
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'PUT',
              responseType: 'json',
              withCredentials: true,
              data: {
                ids,
              },
            });

            this.getItems();
            if (data.status === 200) {
              showSuccess({ message: this.$t('RECORDS_SENT_FOR_CORRECTION') });
            }
          } catch (e) {
            console.log(e); /* eslint-disable-line no-console */
            showError({ message: e });
          }
        } else {
          showWarning({ message: this.$t('SELECT_THE_RECORDS_TO_CORRECT') });
        }
      },
      formattedDate: function (value) {
        return format(parseISO(value), 'dd/MM/yyyy HH:mm');
      },
      toggleEnrolled: async function (item) {
        const result = await Swal.fire({
          title: this.$t('WARNING'),
          text: this.$t('REALLY_WANT_CHANGE_PERSON_ENROLLED', { name: item.name }),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$t('CANCEL'),
          confirmButtonText: this.$t('CONFIRM_CHANGE_PERSON_ENROLLED'),
          confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
        });

        if (!result.value) {
          return;
        }

        try {
          await axios({
            url: '/entry-manual-intervention-change-status',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'PUT',
            responseType: 'json',
            withCredentials: true,
            data: {
              personId: item.person_id,
            },
          });

          this.getItems();
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      deletePerson: async function (item) {
        const result = await Swal.fire({
          title: this.$t('WARNING'),
          text: this.$t('REALLY_WANT_DELETE_PERSON', { name: item.name }),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$t('CANCEL'),
          confirmButtonText: this.$t('CONFIRM_DELETE_PERSON'),
          confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
        });

        if (!result.value) {
          return;
        }

        try {
          await axios({
            url: '/entry-manual-intervention-delete-person',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'PUT',
            responseType: 'json',
            withCredentials: true,
            data: {
              personId: item.person_id,
            },
          });

          this.getItems();
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      openEditModal: async function (item) {
        this.userInEdit = item;
        this.imageFromUserInEdit = null;
        this.newImageToUserInEdit = null;
        this.newBlobImageToUserInEdit = null;
        this.imageInBase64 = null;
        this.allowSendBtn = false;
        this.sendRetry = 0;

        const { data } = await axios({
          url: '/entry-manual-intervention-get-image-with-error',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            personId: item.person_id,
            errorCode: item.code,
            errorMessage: item.message,
          },
        });

        this.userData = data.result;
        if (data.result.segmented_data) {
          this.imageFromUserInEdit = data.result.segmented_data;
        } else {
          this.imageFromUserInEdit = data.result.data;
        }

        this.showEditImageModal = true;
      },
      dismissEditModal: function () {
        this.userInEdit = null;
        this.showEditImageModal = false;
      },
      uploadImage: function () {
        this.$refs.imageInput.$refs.input.click();
      },
      retrySendImage: async function () {
        try {
          const data = await axios({
            url: '/entry-commit',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              id: this.userInEdit.person_id,
            },
          });
          if (data.status === 200) {
            showSuccess({ message: this.$t('IM_IMAGE_SUCCESS_SENDED') });
            this.list.splice(this.list.findIndex((l) => l.cpf === this.userInEdit.cpf), 1);
          } else {
            showError({ message: this.$t('ERROR_SENDING_IMAGE_BCA') });
            if (this.sendRetry === 3) {
              this.sendRetry = 0;
              this.showEditImageModal = false;
              this.userInEdit = null;
            }
          }
        } catch (e) {
          showError({ message: this.$t('ERROR_SENDING_IMAGE_BCA') });
          this.sendRetry++;
          if (this.sendRetry === 3) {
            this.sendRetry = 0;
            this.showEditImageModal = false;
            this.userInEdit = null;
          }
        }
      },
      sendImage: async function () {
        if (this.sendRetry > 0) {
          this.retrySendImage();
        } else {
          let data;
          const base64 = this.imageInBase64.split(',')[1];

          try {
            if (this.userInEdit.code === 'RETRATO_INVALIDO') {
              const image = {};
              if (this.userData.segmented_data) {
                image.segmented_data = base64;
              } else {
                image.data = base64;
              }
              data = await axios({
                url: '/biometry/image/RETRATO',
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'PUT',
                responseType: 'json',
                withCredentials: true,
                data: {
                  personId: this.userInEdit.person_id,
                  image: image,
                },
              });
            } else if (this.userInEdit.code === 'ASSINATURA_INVALIDA') {
              const image = {
                data: base64,
              };
              data = await axios({
                url: '/biometry/image/ASSINATURA',
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'PUT',
                responseType: 'json',
                withCredentials: true,
                data: {
                  personId: this.userInEdit.person_id,
                  image: image,
                },
              });
            } else if (this.userInEdit.code === 'DIGITAL_INVALIDA') {
              const image = {
                capture_type: this.userData.capture_type,
                finger_index: this.userData.finger_index,
                data: base64,
              };
              data = await axios({
                url: '/biometry/image/DIGITAL',
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'PUT',
                responseType: 'json',
                withCredentials: true,
                data: {
                  personId: this.userInEdit.person_id,
                  image: image,
                },
              });
            }

            if (data.status === 200) {
              showSuccess({ message: this.$t('IM_IMAGE_SUCCESS_SENDED') });
              this.list.splice(this.list.findIndex((l) => l.cpf === this.userInEdit.cpf), 1);
            } else {
              showError({ message: this.$t('IM_IMAGE_ERROR_ON_SEND') });
            }
            this.showEditImageModal = false;
            this.userInEdit = null;
          } catch (err) {
            if (err.dbSaveSuccess) {
              await Swal.fire({
                title: this.$t('INTEGRATION_ERROR'),
                text: `${this.$t('ERROR').toUpperCase()}: ${err.error.message.message || err.error.message.menssagem || err.error.message}`,
                icon: 'error',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: this.$t('OK'),
                confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
              });
              this.sendRetry++;
            } else {
              await Swal.fire({
                title: this.$t('IM_IMAGE_ERROR_ON_SEND'),
                text: `${this.$t('ERROR').toUpperCase()}: ${err.error.message.message || err.error.message.menssagem || err.error.message}`,
                icon: 'error',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: this.$t('OK'),
                confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
              });
              showError({ message: this.$t('IM_IMAGE_ERROR_ON_SEND') });
            }
          }
        }
      },
      verifyImage: async function () {
        this.newBlobImageToUserInEdit = URL.createObjectURL(this.newImageToUserInEdit);
        const reader = new FileReader();
        reader.readAsDataURL(this.newImageToUserInEdit);
        reader.onloadend = async () => {
          this.imageInBase64 = reader.result;
          if (this.userInEdit.code === 'RETRATO_INVALIDO' || this.userInEdit.code === 'ASSINATURA_INVALIDA') {
            const type = this.userInEdit.code === 'RETRATO_INVALIDO' ? 'RETRATO' : 'ASSINATURA';
            const { data } = await axios({
              url: '/biometry/image/validate',
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'POST',
              responseType: 'json',
              withCredentials: true,
              data: {
                type: type,
                base64: this.imageInBase64.split(',')[1],
              },
            });
            this.allowSendBtn = data.result.validImage;
            if (!this.allowSendBtn) {
              if (data.result.errorMessage.includes('PROPORÇÃO')) {
                await Swal.fire({
                  title: this.$t('INVALID_IMAGE'),
                  text: this.$t('PROPORTION_ERROR'),
                  icon: 'error',
                  confirmButtonText: this.$t('OK'),
                  confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
                });
              } else if (data.result.errorMessage.includes('TAMANHO')) {
                await Swal.fire({
                  title: this.$t('INVALID_IMAGE'),
                  text: this.$t('IMAGE_SIZE_ERRO'),
                  icon: 'error',
                  confirmButtonText: this.$t('OK'),
                  confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
                });
              } else if (data.result.errorMessage.includes('DPI')) {
                await Swal.fire({
                  title: this.$t('INVALID_IMAGE'),
                  text: this.$t('IMAGE_DPI_ERRO'),
                  icon: 'error',
                  confirmButtonText: this.$t('OK'),
                  confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
                });
              }
            }
          } else {
            this.allowSendBtn = true;
          }
        };
      },
      downloadImage: function () {
        const file = document.createElement('a');
        file.href = `data:image/png;base64,${this.imageFromUserInEdit}`;
        file.download = 'Image.png';
        file.click();
      },
    },
  };
</script>

<style>
  .cursor-pointer:hover {
    cursor: pointer;
  }
</style>
