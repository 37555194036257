var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"wd-100",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"py-4"},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t("SEARCH"),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"warning","elevation":"0"},on:{"click":_vm.recordsetIntervation}},[_vm._v(" "+_vm._s(_vm.$t('CORRECT_REGISTRATIONS'))+" ")])],1),_c('v-data-table',{staticClass:"wd-100",attrs:{"item-key":"person_id","show-select":"","multi-sort":"","search":_vm.search,"headers":_vm.header,"items":_vm.list,"footer-props":{
        itemsPerPageOptions: [30, 50, 100, 300, -1]
      }},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formattedDate(item.created_at))+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-col',[_c('v-row',[(item.code == "ASSINATURA_INVALIDA" || item.code == "RETRATO_INVALIDO" || item.code == "DIGITAL_INVALIDA")?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.openEditModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-image-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('CORRECT_REGISTRATION')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.toggleEnrolled(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-check-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('CHANGE_CANDIDATE_STATE')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.deletePerson(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('DELETE_REGISTRATION')))])])],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"700"},model:{value:(_vm.showEditImageModal),callback:function ($$v) {_vm.showEditImageModal=$$v},expression:"showEditImageModal"}},[_c('v-card',[_c('v-col',[_c('v-row',{staticClass:"py-5 d-flex justify-center align-center"},[_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({attrs:{"flat":""},on:{"click":_vm.downloadImage}},'v-card',attrs,false),on),[_c('v-img',{staticClass:"grey lighten-2 pointer",staticStyle:{"margin-left":"26px","border":"1px solid #eaeaea"},attrs:{"src":("data:image/charset=UTF-8;png;base64," + _vm.imageFromUserInEdit),"max-width":"250","max-height":"350"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('DOWNLOAD_IMAGE')))])])],1),_c('v-divider',{staticClass:"mx-5",staticStyle:{"height":"300px","align-self":"center"},attrs:{"vertical":""}}),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(_vm.newBlobImageToUserInEdit)?_c('v-card',_vm._g(_vm._b({attrs:{"flat":""}},'v-card',attrs,false),on),[_c('v-img',{staticClass:"grey lighten-2 cursor-pointer",staticStyle:{"margin-left":"26px","border":"1px solid #eaeaea"},attrs:{"src":_vm.newBlobImageToUserInEdit,"max-width":"250","max-height":"350"},on:{"click":function($event){return _vm.uploadImage()}}})],1):_c('v-card',_vm._g(_vm._b({staticClass:"d-flex justify-center pointer",attrs:{"outlined":"","height":"350"},on:{"click":function($event){return _vm.uploadImage()}}},'v-card',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"200px"},attrs:{"x-large":""}},[_vm._v(" mdi-account ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('UPLOAD_IMAGE')))])])],1)],1),_c('v-file-input',{ref:"imageInput",staticClass:"d-none",attrs:{"accept":"image/jpeg","truncate-length":"25"},on:{"change":_vm.verifyImage},model:{value:(_vm.newImageToUserInEdit),callback:function ($$v) {_vm.newImageToUserInEdit=$$v},expression:"newImageToUserInEdit"}}),_c('v-row',{staticClass:"ma-3 d-flex justify-end"},[_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":"","color":"error"},on:{"click":_vm.dismissEditModal}},[_vm._v(" "+_vm._s(_vm.$t('CANCEL'))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.allowSendBtn,"color":"success"},on:{"click":_vm.sendImage}},[_vm._v(" "+_vm._s(_vm.sendRetry > 0 ? _vm.$t('RETRY') : _vm.$t('CONFIRM'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }